import { EoiStatus } from "@/enums/EoiStatus";

export const getLandownerEoiStatus = (status: EoiStatus) => {
  if (status === EoiStatus.WAITING_FOR_APPROVED) {
    return "Approving";
  }
  if (status === EoiStatus.WAITING_FOR_DECLINED) {
    return "Declining";
  }
  if (status === EoiStatus.REFUNDED) {
    return "Deposit refunded";
  }
  if (status === EoiStatus.REFUND_DECLINED) {
    return "Deposit not refunded";
  }
  if (status === EoiStatus.TRANSACTION_ERROR) {
    return "Transaction error";
  }
  return status;
};

export function getStatusText(status: EoiStatus): string {
  switch (status) {
    case EoiStatus.PENDING:
      return "Pending";
    case EoiStatus.APPROVED:
      return "Approved";
    case EoiStatus.DECLINED:
      return "Declined";
    case EoiStatus.WAITING_FOR_APPROVED:
      return "Approving";
    case EoiStatus.WAITING_FOR_DECLINED:
      return "Declining";
    case EoiStatus.WITHDRAWN:
      return "Withdrawn";
    case EoiStatus.REFUNDED:
      return "Deposit refunded";
    case EoiStatus.REFUND_DECLINED:
      return "Deposit not refunded";
    case EoiStatus.TRANSACTION_ERROR:
      return "Transaction error";
    default:
      return "Pending";
  }
}

export const getEoiRequestForm = () => (
  {
    buyerType: "Owner Occupier",
    buyersData: {
      firstBuyer: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        postcode: "",
        address: "",
        state: "",
        suburb: "",
        documents: [
          {
            name: "",
            link: "",
            documentType: "Drivers license",
            modifiedDate: "",
          },
        ],
      },
      secondBuyer: {
        firstName: "",
        middleName: "",
        lastName: "",
        suburb: "",
        postcode: "",
        state: "",
        email: "",
        phone: "",
        address: "",
        documents: [
          {
            name: "",
            link: "",
            documentType: "Drivers license",
            modifiedDate: "",
          },
        ],
      },
    },
    conveyancerDetails: {
      firmName: "",
      address: "",
      state: "",
      suburb: "",
      postcode: "",
      contactPerson: {
        fullName: "",
        email: "",
        phone: "",
      },
    },
    companyDetails: {
      name:"",
      businessIdentifier: "",
      address: "",
      suburb: "",
      state: "",
      postcode: "",
    },
    contractConditions: {
      subjectToFinance: false,
      financeStatus: "Pending",
      landLoanAmount: null,
      FIRB: false,
      otherConditions: "",
      bankApprovalDocument: {
        name: "",
        link: "",
        documentType: "",
        modifiedDate: "",
      },
      financeBrokerDetails: {
        lendingInstitution: "",
        brokerName: "",
        email: "",
        phone: "",
      }
    },
    agentReferrer: "",
  }
);

export const getZipConveyancerDetails = () => (
  {
    firmName: "Zip Conveyancing",
    address: "Level 2, 75 Surf Parade",
    postcode: "4218",
    state: "QLD",
    suburb: "Broadbeach",
    contactPerson: {
      fullName: "Aimee",
      email: "aimee@zipconveyancing.com.au",
      phone: "0452 459 595",
    },
  }
)

export const getEmptyConveyancerDetails = () => (
  {
    firmName: "",
    address: "",
    postcode: "",
    state: "",
    suburb: "",
    contactPerson: {
      fullName: "",
      email: "",
      phone: "",
    },
  }
);
