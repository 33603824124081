import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a376937e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-title" }
const _hoisted_3 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Modal"], {
    isOpen: $props.isOpen,
    onClose: $setup.close
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "description", {}, undefined, true)
        ]),
        _createElementVNode("div", { class: "controls" }, [
          _createElementVNode("button", {
            class: "button cancel",
            onClick: $setup.close
          }, "Cancel"),
          _createElementVNode("button", {
            class: "button primary",
            onClick: $setup.confirm
          }, "Confirm")
        ])
      ])
    ]),
    _: 3
  }, 8, ["isOpen"]))
}