import { ContactStatus } from "@/enums/ContactStatus";
import { ContactStatusInterface } from "@/types/ContactInterface";

export const contactStatus = (
  contactArray: ContactStatusInterface[],
  estateId: string
): string => {
  const array = contactArray.filter((item) => {
    return item.estateId === estateId;
  });
  return array[0] ? array[0].contactStatus : ContactStatus.PROSPECT;
};
