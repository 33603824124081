import store from "@/store";
import { isImage } from "./documents";

export function downloadFile(filename: string, estateId = ""): void {
  if (estateId) {
    store
      .dispatch("estate/getEstateFile", { id: estateId, filename })
      .then((res) => {
        open(filename, res.data);
      });
  } else {
    open(filename);
  }
}

function open(filename: string, src = "") {
  window.open(src || filename, isImage(filename) ? "_self" : "_blank");
}
