import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/icon-download-slim.svg'


const _withScopeId = n => (_pushScopeId("data-v-a45e1952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document-item--container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "document-item--name" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "document-item--wrapper",
    onClick: $setup.openFile
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "document-item--preview",
        src: $setup.previewIcon($props.link, $props.name)
      }, null, 8, _hoisted_2),
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.name), 1)
    ]),
    _createElementVNode("img", {
      class: "document-item--download",
      src: _imports_0,
      onClick: _withModifiers($setup.download, ["stop","prevent"])
    }, null, 8, _hoisted_4),
    _createVNode($setup["ImagePreviewModal"], {
      showImg: $setup.showImg,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.showImg = undefined))
    }, null, 8, ["showImg"])
  ]))
}