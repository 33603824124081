import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-857011de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Modal"], {
    isOpen: $props.showImg,
    onClose: $setup.close,
    showContainer: false
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "close",
            onClick: $setup.close
          }),
          _createElementVNode("img", {
            src: $props.showImg,
            alt: "Preview"
          }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    _: 1
  }, 8, ["isOpen"]))
}