const imageFormats = ["jpg", "jpeg", "svg", "png", "gif"];

export const getFileType = (imgLink: string): string | undefined => {
  if (!imgLink) {
    return undefined;
  } else return imgLink.split(".").pop();
};

export const isImage = (fileName: string): boolean => {
  const type = getFileType(fileName);
  return type ? imageFormats.includes(type) : false;
};

export const previewIcon = (fileLink: string, fileName = ""): string => {
  const image = isImage(fileName || fileLink);
  return image ? fileLink : require(`@/assets/icons/icon-pdf.svg`);
};

export function getDocumentName(name: string) {
  const parts = name.split("/");
  return parts[parts.length - 1];
}
