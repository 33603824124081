import router from "@/router";

export const hasHistory = () => {
  return window.history.length > 2;
};

export const toPreviousPage = () => {
  if (hasHistory()) {
    router.go(-1);
  } else router.push("/");
};
