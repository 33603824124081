import { LotRangeInterface } from "@/types/LotRangeInterface";

export const defaultRange: LotRangeInterface = {
  size: {
    min: 100,
    max: 3000,
  },
  frontage: {
    min: 5,
    max: 50,
  },
  budget: {
    min: 100000,
    max: 1000000,
  },
};
