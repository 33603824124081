import dayjs from "dayjs";

export function formatDate(date: string, format = "D MMM YYYY"): string {
  return date !== undefined && dayjs(date).isValid()
    ? dayjs(date).format(format)
    : "-";
}

export function formatDateMonthYear(
  date: string | Date,
  format = "MMM YYYY"
): string {
  return dayjs(date).format(format);
}
export function formatDateMonthDayYear(date: Date): string {
  return dayjs(date).format("MMM D, YYYY");
}
export function formatDateDayMonthYear(date: Date): string {
  return dayjs(date).format("D MMM YYYY");
}

export function formatDateCalendar(date: Date): string {
  return dayjs(date).format("YYYY/M/D");
}
export function formatDateDayMonthYearWithTime(date: Date): string {
  return dayjs(date).format("D MMM YYYY, h:mm A");
}

export function formatDateYYYYMMDD(date: string | Date): string {
  return dayjs(date).format("YYYY-MM-DD");
}

export function formatDateToday(date: string | Date): string {
  return dayjs(date).format("DD MMMM YYYY");
}
